export const menuItems = {administrator :[
    {
        id: 1,
        label: "menuitems.menu.text",
        isTitle: true
    },
    {
        id: 2,
        label: 'menuitems.dashboard.text',
        icon: 'ri-dashboard-line',
        link: '/'
    },
    /*{
        id: 100,
        label: 'menuitems.executivedashboard.text',
        icon: 'ri-chat-1-line',
        subItems: [
            {
                id: 101,
                label: 'Dashboard',//'menuitems.executivedashboard.list.dashboardfinance',
                link: '/executivedashboard/dashboardfinance'
            },
            {
                id: 102,
                label: 'menuitems.executivedashboard.list.dashboardoperation',
                link: '/executivedashboard/dashboardoperation'
            }
        ]
    },*/
    {
        id: 200,
        label: 'menuitems.masterdata.text',
        icon: 'ri-chat-1-line',
        subItems: [
            {
                id: 201,
                label: 'menuitems.masterdata.list.masterport',
                link: '/masterdata/port'
            },
            {
                id: 202,
                label: 'menuitems.masterdata.list.mastercustomer',
                link: '/masterdata/customer/lists'
            },
            {
                id: 203,
                label: 'menuitems.masterdata.list.mastervendor',
                link: '/masterdata/vendor/lists'
            },
            {
                id: 204,
                label: 'menuitems.masterdata.list.mastervendorconfigprt',
                link: '/masterdata/vendorconfigprt/lists'
            },
            {
                id: 205,
                label: 'menuitems.masterdata.list.mastervendorconfigaircraft',
                link: '/masterdata/vendorconfigaircraft/lists'
            },
            /*{
                id: 203,
                label: 'menuitems.masterdata.list.mastervendflight',
                link: '/masterdata/vendorflight/lists'
            },*/
            {
                id: 206,
                label: 'menuitems.masterdata.list.masterscheduleprice',
                link: '/masterdata/vendorrouteprice/lists'
            },
            {
                id: 205,
                label: 'menuitems.masterdata.list.masterschedule',
                link: '/masterdata/vendorschedule/lists'
            },
            {
                id: 204,
                label: 'menuitems.masterdata.list.smunumberstock',
                link: '/invoice/smu/lists'
            }
            /*,
            
            {
                id: 205,
                label: 'menuitems.masterdata.list.mastersaleprice',
                link: '/masterdata/saleprice/lists'
            }*/
        ]
    },
    {
        id: 300,
        label: 'menuitems.bookingorder.text',
        icon: 'ri-chat-1-line',
        subItems: [
            {
                id: 302,
                label: 'menuitems.bookingorder.list.customerinvoicelist',
                link: '/manageorder/lists'
            },
            {
                id: 303,
                label: 'menuitems.bookingorder.list.ordercustlist',
                link: '/order/lists'
            },
            {
                id: 303,
                label: 'menuitems.bookingorder.list.orderreport',
                link: '/report/order'
            }
            /*,
            {
                id: 305,
                label: 'menuitems.bookingorder.list.orderverificationadd',
                link: '/orderverification/add'
            }*/
        ]
    },
    {
        id: 800,
        label: 'menuitems.billing.text',
        icon: 'ri-chat-1-line',
        subItems: [
            {
                id: 801,
                label: 'menuitems.billing.list.billinglist',
                link: '/billing/invoiceinternal/lists'
            },
            {
                id: 802,
                label: 'menuitems.billing.list.billingreport',
                link: '/billing/report'
            },
            {
                id: 803,
                label: 'menuitems.billing.list.revenuereport',
                link: '/billing/revenue'
            }
            /*,
            {
                id: 802,
                label: 'menuitems.billing.list.paymentconfirmationadd',
                link: '/paymentconfirmation/add'
            },
            {
                id: 803,
                label: 'menuitems.billing.list.paymentreceiptadd',
                link: '/paymentreceipt/add'
            },
            {
                id: 804,
                label: 'menuitems.billing.list.invoiceadjustment',
                link: '/billing/invoice/adjustment'
            }*/
        ]
    },
    /*{
        id: 400,
        label: 'menuitems.incoming.text',
        icon: 'ri-chat-1-line',
        subItems: [
            {
                id: 401,
                label: 'menuitems.incoming.list.incomingbooking',
                link: '/incoming/searchincoming'
            },
            {
                id: 402,
                label: 'menuitems.incoming.list.incominglist',
                link: '/incoming/lists'
            }
        ]
    },
    {
        id: 500,
        label: 'menuitems.handover.text',
        icon: 'ri-chat-1-line',
        subItems: [
            {
                id: 501,
                label: 'menuitems.handover.list.handoveradd',
                link: '/handover/add'
            },
            {
                id: 502,
                label: 'menuitems.handover.list.handoverlist',
                link: '/handover/lists'
            }
        ]
    },*/
    {
        id: 600,
        label: 'menuitems.monitoring.text',
        icon: 'ri-chat-1-line',
        subItems: [
            {
                id: 601,
                label: 'menuitems.monitoring.list.tracking',
                link: '/monitoring/tracking'
            },
            /*{
                id: 602,
                label: 'menuitems.monitoring.list.updateschedulestatus',
                link: '/monitoring/updateschedulestatus'
            },*/
            {
                id: 603,
                label: 'menuitems.monitoring.list.updateorderstatus',
                link: '/monitoring/updateorderstatus'
            }
        ]
    },
    {
        id: 700,
        label: 'menuitems.users.text',
        icon: 'ri-chat-1-line',
        subItems: [
            {
                id: 701,
                label: 'menuitems.users.list.lists',
                link: '/users/lists'
            }/*,
            {
                id: 702,
                label: 'menuitems.users.list.userrole',
                link: '/users/userrolelists'
            }*/
        ]
    }
],


customer:[
    {
        id: 1,
        label: "menuitems.menu.text",
        isTitle: true
    },
    {
        id: 2,
        label: 'menuitems.dashboard.text',
        icon: 'ri-dashboard-line',
        link: '/'
    },
    {
        id: 300,
        label: 'menuitems.bookingorder.text',
        icon: 'mdi mdi-cube-send',
        subItems: [
            {
                id: 301,
                label: 'menuitems.bookingorder.list.bookingorderadd',
                link: '/bookingorder/searchflight'
            },
            // {
            //     id: 301,
            //     label: 'menuitems.bookingorder.list.bookingorderprojectadd',
            //     link: '/bookingorder/project/add'
            // },
            {
                id: 303,
                label: 'menuitems.bookingorder.list.ordercustlist',
                link: '/order/lists'
            },
        ]
    },
    {
        id: 800,
        label: 'menuitems.billing.text',
        icon: 'fas fa-file-invoice-dollar',
        subItems: [
            {
                id: 801,
                label: 'menuitems.billing.list.billinglist',
                link: '/billing/invoice/lists'
            }
        ]
    },
    {
        id: 600,
        label: 'menuitems.monitoring.list.tracking',
        icon: 'mdi mdi-map-marker-distance',
        link: '/monitoring/tracking'
    }
],

customerAdmin:[
    {
        id: 1,
        label: "menuitems.menu.text",
        isTitle: true
    },
    {
        id: 2,
        label: 'menuitems.dashboard.text',
        icon: 'ri-dashboard-line',
        link: '/'
    },
    {
        id: 300,
        label: 'menuitems.bookingorder.text',
        icon: 'mdi mdi-cube-send',
        subItems: [
            {
                id: 301,
                label: 'menuitems.bookingorder.list.bookingorderadd',
                link: '/bookingorder/searchflight'
            },
            // {
            //     id: 301,
            //     label: 'menuitems.bookingorder.list.bookingorderprojectadd',
            //     link: '/bookingorder/project/add'
            // },
            {
                id: 303,
                label: 'menuitems.bookingorder.list.ordercustlist',
                link: '/order/lists'
            },
        ]
    },
    {
        id: 800,
        label: 'menuitems.billing.text',
        icon: 'fas fa-file-invoice-dollar',
        subItems: [
            {
                id: 801,
                label: 'menuitems.billing.list.billinglist',
                link: '/billing/invoice/lists'
            }
        ]
    },
    {
        id: 600,
        label: 'menuitems.monitoring.list.tracking',
        icon: 'mdi mdi-map-marker-distance',
        link: '/monitoring/tracking'
    },
    {
        id: 700,
        label: 'menuitems.users.text',
        icon: 'ri-chat-1-line',
        subItems: [
            {
                id: 701,
                label: 'menuitems.users.list.lists',
                link: '/users/lists'
            }
        ]
    }
],
vendor:[
    {
        id: 901,
        label: "menuitems.menu.text",
        isTitle: true
    },
    {
        id: 902,
        label: 'menuitems.dashboard.text',
        icon: 'ri-dashboard-line',
        link: '/'
    },
    {
        id: 903,
        label: 'menuitems.masterdata.list.masterschedule',
        icon: 'mdi mdi-timetable',
        link: '/masterdata/vendorschedule/lists'
    },
    {
        id: 603,
        label: 'menuitems.monitoring.list.updateorderstatus',
        icon: 'ri-map-pin-line',
        link: '/monitoring/updateorderstatus'
    }
]
}